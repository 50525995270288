
var markerClickHandler = function() {
  window.location.href = this.url;
};

var added = {};

function addMarker(map, event) {

  var lat = Number(event.lat);
  var lon = Number(event.lon);
  var key = event.lat + "," + event.lon;
  if(added[key] !== undefined) {
    lat = lat + (Math.random() - 0.5) / 1500;
    lon = lon + (Math.random() - 0.5) / 1500;
  } else {
    added[key] = true;
  }

  var marker = new google.maps.Marker({
    position: {lat: lat, lng: lon},
    map: map,
    title: event.title,
    url: event.url
  });

  marker.addListener('click', markerClickHandler);

  return marker;
}

export default function displayMap(container, events) {

  const map = new google.maps.Map(container, {
    zoom: 2,
    minZoom: 1,
    center: {lat: 18, lng: 0}
  });

  window.myMap = map;

  var markers = [];
  for (var i = 0; i !== events.length ; i++) {

    markers.push(addMarker(map, events[i]));
  }

  var markerCluster = new MarkerClusterer(map, markers, {
    gridSize: 40,
    imagePath: 'vendor/imaginary-events/img/m',
  });
}
