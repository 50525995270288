/* eslint-disable new-cap */
import displayMap from './eventMap';
import './drupalMocks';

$(() => {
// Event map and browser
  $.ajax('https://www.imaginary.org/api/events.json?lang=en', {
    dataType: 'json',
    success: (data) => {

      const now = new Date();
      let eventCount = 0;
      const countries = {};
      for (let i = 0; i !== data.events.length; i += 1) {
        if (new Date(data.events[i].dateFrom) < now) {
          eventCount += 1;
          if (countries[data.events[i].country] === undefined) {
            countries[data.events[i].country] = 1;
          } else {
            countries[data.events[i].country] += 1;
          }
        }
      }

      $('.imaginary_event-map').each((i, map) => {
        displayMap(map, data.events);
      });

      $('[data-component=event-grid]').each(() => {
        const category = $(this).attr('data-category');
        if (category === 'permanent') {
          window.IMAGINARY.EventGrid(this, permamentEvents, 'There are no permanent events');
        } else if (category === 'future') {
          window.IMAGINARY.EventGrid(this, futureEvents, 'There are no upcoming events. Check back soon.');
        } else if (category === 'current') {
          window.IMAGINARY.EventGrid(this, currentEvents, 'There are no ongoing events.');
        }
      });

      $('[data-component=year-grouped-event-list]').each((i, container) => {
        window.IMAGINARY.YearGroupedEventList(container, data.events);
      });

      $('[data-component=country-grouped-event-list]').each((i, container) => {
        window.IMAGINARY.CountryGroupedEventList(container, data.events);
      });

      $('[data-token=event-count]').text(eventCount);
      $('[data-token=country-count]').text(Object.keys(countries).length);
    },
  });
});

// Open external links in new tab
$(() => {
  $(document.links)
    .filter((i, eachLink) => eachLink.hostname !== window.location.hostname)
    .attr('target', '_blank');
});

$(() => {
  // Setup the slider
  $('#slider').slick({
    dots: false,
    arrows: true,
    variableWidth: true,
    speed: 300,
    slidesToShow: 1,
    swipeToSlide: true,
    centerMode: true,
    infinite: true,
    // slidesToShow: 8,
    // slidesToScroll: 8,
    // // the magic
    // responsive: [{
    //   breakpoint: 1024,
    //   settings: {
    //     slidesToShow: 3,
    //   },
    // }, {
    //   breakpoint: 600,
    //   settings: {
    //     slidesToShow: 2,
    //   },
    // }, {
    //   breakpoint: 300,
    //   settings: 'unslick' // destroys slick
    // }],
  });
});
